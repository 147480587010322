<template>
  <div>
    <NotificationListCard :visible="notificationVisible" v-on:update:visible="notificationVisible = $event" />
  </div>
</template>

<script setup>

import { computed } from 'vue';
import { useSocketStore } from '~/stores/socketStore';
import { useOrderStore } from '~/stores/orderStore';
import { useNotificationStore } from '~/stores/notificationStore';
import { useNuxtApp } from '#app';

const orderStore = useOrderStore();
const notificationStore = useNotificationStore();
const notificationVisible = ref(false);

const props = defineProps({
  identifier: {
    type: String,
    required: true
  },
  token: {
    type: String,
    required: true
  }
});

const config = useRuntimeConfig()
const nuxtApp = useNuxtApp();
const socketStore = useSocketStore(nuxtApp.vueApp);
const message = computed(() => socketStore.message);

watch(message, (newMessage) => {
  if (newMessage?.data) {
    notificationVisible.value = true;

    notificationStore.addNotification({
      id: Date.now(),
      message: `Novo pedido realizado - ${newMessage.data.order.order_number}`,
      duration: 8000,
    });

    orderStore.addOrder(newMessage.data.order);
  }
})

onMounted(() => {
  const startConnectionMonitor = nuxtApp.$startConnectionMonitor;
  connectWebSocket();

  nuxtApp.$onWebSocketConnected(() => {
    authenticateWebSocket();
    startConnectionMonitor();
  });
});


const connectWebSocket = () => {
  const connectWebSocket = nuxtApp.$connectWebSocket;
  if (connectWebSocket) {
    connectWebSocket(config.public.websocketUrl, config.public.applicationToken, 'MANAGEMENT_ORDER');
  } else {
    console.error('WebSocket connection function is not available.');
  }
};

const authenticateWebSocket = () => {
  const authenticateWebSocket = nuxtApp.$authenticateWebSocket;

  if (authenticateWebSocket) {
    authenticateWebSocket(props.token, props.identifier);
  } else {
    console.error('WebSocket authentication function is not available.');
  }
};

// const sendMessage = () => {
//   const socket = nuxtApp.vueApp.config.globalProperties.$socket;
//   if (socket && socket.send) {
//     socket.send(JSON.stringify({ identifier: 'MANAGEMENT_ADMIN', type: 'authenticate', token: authStore.token }));
//   } else {
//     console.error('WebSocket connection is not established.');
//   }
// };

</script>

<style scoped></style>
